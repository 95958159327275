@use 'global_styles/_reset.scss';
@use 'global_styles/_globals.scss';
@use 'global_styles/_grid.scss';
@use 'global_styles/_typography.scss';
@use 'global_styles/_animation.scss';
@use 'global_styles/_scrollspy.scss';
@use 'global_styles/_emojis.scss';


.App {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
