@import '../../global_styles/_variables.scss';
@import '../../global_styles/_mixins.scss';

.About {
  color: $white;
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  justify-items: center;
  svg {
    width: 150px;
    height: auto;
  }
  .copy-wrapper {
    margin-top: 45px;
    width: 100%;
    h2,
    h3,
    p {
      text-align: center;
    }
    > * {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    span {
      text-transform: uppercase;
    }
    code {
      font-family: monospace;
      text-transform: uppercase;
      font-size: 24px;
    }
  }
  ul {
    margin-left: 20px;
  }
  @include devices(tablet) {
    flex-direction: row;
    justify-items: center;
    align-items: center;
    .copy-wrapper {
      width: 400px;
      margin-top: 0;
      margin-right: 50px;
      h2,
      h3,
      p {
        text-align: left;
      }
    }
    svg {
      height: 400px;
      width: auto;
    }
  }
  @include devices(desktop) {
    width: auto;
    padding: 90px;
    // height: 450px;
    .copy-wrapper {
      width: 575px;
    }
    svg {
      width: auto;
      height: 450px;
    }
  }
  @include devices(widescreen) {
    // height: 500px;
    padding: 100px;
    svg {
      height: 500px;
    }
  }
  @include devices(fullhd) {
    // height: 550px;
    padding: 160px;
    svg {
      height: 550px;
    }
  }
}
