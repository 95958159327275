html {
  -webkit-font-smoothing: subpixel-antialiased;
  height: -webkit-fill-available;
}

body {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

em {
  font-weight: 700;
}

ul {
  margin-left: 20px;
}

option, select {
  color: black;
}

select {
  padding: 5px 10px;
  border-radius: 6px;
  text-align: center;
  text-align-last: center;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &::-ms-expand {
    display: none;
  }
}
