@import '../../global_styles/_mixins.scss';
@import '../../global_styles/_variables.scss';

.Contact {
  .contact-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .contact-sublinks {
      display: flex;
      a {
        margin: 0 5px;
      }
    }
  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-end;
    margin-bottom: 1em;
    input,
    textarea {
      width: 100%;
      padding: 1rem;
      background-color: $gray;
      border: none;
      margin: 1rem 0;
    }
    textarea {
      min-height: 300px;
    }

    @include devices(tablet) {
      input {
        margin-left: 1rem;
        &:first-of-type {
          margin-left: 0;
          margin-right: 1rem;
        }
      }
      textarea {
        // min-height: 500px;
      }
    }
    @include devices(desktop) {
    }
    @include devices(widescreen) {
    }
    @include devices(fullhd) {
    }

  }
  .form-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    @include devices(tablet) {
      flex-direction: row;
      justify-content: center;
    }
    @include devices(desktop) {
    }
    @include devices(widescreen) {
    }
    @include devices(fullhd) {
    }
  }

}
