@import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:wght@300;700&display=swap');
@import './_variables.scss';
@import './_mixins.scss';

html {
  font-size: 19px;
  @include devices(tablet) {
    font-size: 17px;
  }
  @include devices(desktop) {
    font-size: 18px;
  }
  @include devices(widescreen) {
    font-size: 20px;
  }
  @include devices(fullhd) {
    font-size: 21px;
  }
}


body {
  font-family: 'Hanken Grotesk', sans-serif;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.5rem;
  p {
    orphans: 2;
  }
}

h1,
h2,
h3,
select {
  font-weight: 700;
}

h1 {
  font-size: 1.875rem;
}

h2 {
  font-size: 1.5rem;
}

select, h3 {
  font-size: 1.067rem;
}

select {
  font-size: 1rem;
}

option {
  font-weight: 300;
}

input, textarea {
  font-family: 'Hanken Grotesk', sans-serif;
  font-weight: 300;
  font-size: 1rem;
}