@import '../../global_styles/_mixins.scss';

.Container {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  .script-svg {
    width: 100%;
    height: 100px;
  }
  @include devices(tablet) {
    .script-svg {
      width: auto;
      height: 150px;
      flex-shrink: 0;
    }
  }
  @include devices(desktop) {
    .script-svg {
      width: auto;
      height: 180px;
    }
  }
  @include devices(widescreen) {
    .script-svg {
      width: auto;
      height: 200px;
    }
  }
  @include devices(fullhd) {
    .script-svg {
      width: auto;
      height: 250px;
    }
  }
}
