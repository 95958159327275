@import '../../global_styles/_variables.scss';
@import '../../global_styles/_mixins.scss';

.Services {
  height: auto;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  .services-wrapper {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    position: relative;
    width: 100%;

    > * {
      margin-top: 15px;
    }

    // .services-svg {
    //   width: 310px;
    //   height: auto;
    //   min-height: 10vh;
    // }

    .services-inner-wrapper {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      > * {
        margin-top: 15px;
        &:first-child {
          margin-top: 0;
        }
      }
    }

    .services-headings-svg {
      height: 80px;
      margin: 3vh 0 2vh 0;
      stroke: black;
    }
  }
  .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    ul {
      width: 250px;
    }
  }
  @include devices(tablet) {
    padding: 60px;
    .services-wrapper {
      > * {
        margin-right: 0;
      }
      // .services-svg {
      //   width: auto;
      //   height: 100px;
      // }
      .services-inner-wrapper {
        flex-direction: row;
        justify-content: space-evenly;
        align-items: flex-start;
        width: 100%;
        > * {
          margin-top: 0;
          margin-right: 60px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .card {
        max-width: 400px;
        margin-right: 0;
        ul {
          width: 200px;
        }
      }
    }
  }
  @include devices(desktop) {
    .services-wrapper {
      // .services-svg {
      //   width: auto;
      //   height: 120px;
      // }
      // > * {
      //   margin-right: 80px;
      //   &:last-child {
      //     margin-right: 0;
      //   }
      // }
    }
  }
  @include devices(widescreen) {
    .services-wrapper {
      // .services-svg {
      //   width: auto;
      //   height: 140px;
      // }
    }
  }
  @include devices(fullhd) {
    .services-wrapper {
      // .services-svg {
      //   width: auto;
      //   height: 150px;
      // }
    }
  }
}

@media only screen and (min-width: $mobile-min-width) and (max-width: $tablet-max-width) and (orientation: landscape) {
  /* Your styles here */
  .Services {
        padding: 60px 0px;
    .services-wrapper {
      // .services-svg {
      //   width: auto;
      //   height: 100px;
      // }
    }
  }
}