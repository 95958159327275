@import './_mixins.scss';
@import './_variables.scss';

.Screen {
  .animate-svg {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    &.force {
      @include animate-svg-delay(0.15s, 1.5s, $easeOut);
    }
  }
  &.scrolled {
    .animate-svg {
      @include animate-svg-delay(0.5s, 2.5s, linear);
    }
    .animate-svg-no-children {
      stroke-dashoffset: 1000;
      animation: dash 2.5s linear forwards;
    }
  }
  .animate-svg-no-children {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
