@import '../../global_styles/_mixins.scss';

.Portfolio {
  width: 100%;
  height: 100%;
  padding: 90px 0 0 0;
  .portfolio-inner-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    > * {
      margin-top: 15px;
      &:first-child {
        margin-top: 0;
      }
    }
  }
  // svg {
  //   width: 150px;
  //   min-height: 10vh;
  // }
  .tag-container {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    select {
      margin-bottom: 5px;
    }
    .selected-tags-container {
      display: flex;
      justify-content: center;
      width: 100%;
      flex-wrap: wrap;
      > * {
        margin: 2.5px;
      }
    }
  }
  .portfolio-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    .project-card {
      height: 50vw;
      width: 50vw;
      display: flex;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      align-items: flex-start;
      justify-content: center;
      color: white;
      cursor: pointer;
      transition: opacity 0.2s $easeOut;

      h3 {
        background-color: #00000080;
        width: 100%;
        text-align: center;
        padding: 0.5em 0;
      }
      &:hover {
        opacity: .85;
      }
    }
  }
  @include devices(tablet) {
    // svg {
    //   width: auto;
    //   height: 170px;
    // }
    .tag-container {
      select {
        margin-bottom: 0;
        transition: all 0.2s $easeOut;
        cursor: pointer;
        &:hover {
          background-color: black;
          color: white;
        }
      }
      .selected-tags-container {
        > * {
          margin: 10px 5px;
        }
      }
    }
    .portfolio-grid {
      grid-template-columns: repeat(3, 1fr);
      .project-card {
        height: 33.3vw;
        width: 33.3vw;
      }
    }
  }
  @include devices(desktop) {
    .portfolio-inner-wrapper {
      > * {
        margin-top: 30px;
        &:first-child {
          margin-top: 0;
        }
      }
    }

    // svg {
    //   width: auto;
    //   height: 180px;
    // }
    .portfolio-grid {
      grid-template-columns: repeat(4, 1fr);
      .project-card {
        width: 25vw;
        height: 25vw;
        border: 1px solid black;
      }
    }
  }
  @include devices(widescreen) {
    // svg {
    //   width: auto;
    //   height: 190px;
    // }
  }
  @include devices(fullhd) {
    // svg {
    //   width: auto;
    //   height: 200px;
    // }
  }
}
