/* CSS */
.fullscreen-menu {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: white;
  transition: all 0.3s ease-in-out;
  z-index: 1;
  transform: translateX(-100vw);
  opacity: 0;
  &.open {
    transform: translateX(0);
    opacity: 1;
  }
   & ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
    }
    & li {
      margin: 1rem 0;
      width: 100%;
    }
    & a {
      text-decoration: none;
      color: black;
      font-size: 2rem;
    }
    & svg {
      width: 100%;
      height: 40px;
    }
}
