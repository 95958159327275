@import '../../global_styles/_mixins.scss';
@import '../../global_styles/_variables.scss';

.Screen {
  background-color: $black;
  padding: 90px 30px 30px;
  width: 100%;
  position: relative;
  min-height: 100vh;
  height: auto;
  display: flex;
  &:nth-of-type(odd) {
    background-color: $white;
  }
  &.full-bleed {
    width: 100vw;
  }
  @include devices(tablet) {
    padding: 60px 35px;
  }
  @include devices(desktop) {
    padding: 60px 90px;
  }
  @include devices(widescreen) {
    padding: 60px 100px;
  }
  @include devices(fullhd) {
    padding: 60px 160px;
  }
  &.Splash {
    justify-content: center;
    align-items: center;
    padding-top: 0;
    .scroll-button {
      position: absolute;
      bottom: 15px;
      @include devices(tablet) {
        bottom: 30px;
        .button {
          &:hover {
            span{
              transform: translateX(10px);
            }
          }
        }
      }
    }
    svg {
      width: 350px;
      @include devices(tablet) {
        width: 400px;
      }
      @include devices(desktop) {
        width: 450px;
      }
      @include devices(widescreen) {
        width: 500px;
      }
      @include devices(fullhd) {
        width: 600px;
      }
    }
    .Button {
      border: none;
    }
  }
}

.mobile-safari {
  .Screen.Splash .scroll-button {
    bottom: 110px;
  }
}

@media only screen and (min-width: $mobile-min-width) and (max-width: $tablet-max-width) and (orientation: landscape) {
  /* Your styles here */
  .Screen {
    &.Splash {
      padding: 0;
      svg {
        height: 280px;
        width: auto;
      }
      .scroll-button {
        bottom: 5px;
      }
    }
  }
}
