@import '../../global_styles/_variables.scss';

.Button {
  background-color: white;
  color: black;
  padding: 5px 10px;
  border: 2px solid black;
  border-radius: 6px;
  width: fit-content;
  cursor: pointer;
  transition: all 0.2s $easeOut;
  &:hover, &:active {
    background-color: black;
    color: white;
    border-color: white;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  .active-scroll-spy::before {
    content: '';
  }
}