@import '../../global_styles/_variables.scss';
@import '../../global_styles/_mixins.scss';

.desktop-sticky-nav {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;

  display: flex;
  align-content: center;
  justify-content: space-evenly;

  width: 100%;
  padding: 10px;

  transition: all 0.3s $easeOut;
  transform: translate3d(0, -20vh, 0);
  pointer-events: none;

  background-color: #ffffff;
  box-shadow: 0 2px 50px 5px rgba(0, 0, 0, 0.1);

  &.visible {
    transform: translate3d(0, 0, 0);
    pointer-events: auto;
  }
}

.data-to-scrollspy {
  transform: scale(1);
  transition: all 0.3s ease-out;
}

.active-scroll-spy {
  transform: scale(1.1);
  &::before {
    content: '👉';
    display: inline-block;
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 99999;
    @include devices(tablet) {
      left: -30px;
    }
    // @include devices(desktop) {
    //   font-size: 18px;
    // }
    // @include devices(widescreen) {
    //   font-size: 20px;
    // }
    // @include devices(fullhd) {
    //   font-size: 24px;
    // }
  }
}
.nav-item {
  display: flex;
  align-items: center;
  svg {
    width: auto;
    height: 20px;
  }
}

.nav-item.logo {
  svg {
    width: 75px;
    height: auto;
  }
  .active-scroll-spy::before {
    content: '';
  }

}

.mobile-sticky-nav {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;

  display: flex;
  align-content: center;
  justify-content: space-between;

  width: 100%;
  padding: 10px;

  transition: all 0.3s $easeOut;
  transform: translate3d(0, -20vh, 0);
  pointer-events: none;

  background-color: #ffffff;
  box-shadow: 0 2px 10px 5px rgba(0, 0, 0, 0.08);

  &.visible {
    transform: translate3d(0, 0, 0);
    pointer-events: auto;
  }
  .nav-item {
    svg {
      width: auto;
      height: 50px;
    }
  }
}
