@import '../../global_styles/_mixins.scss';
@import '../../global_styles/_variables.scss';

.modal-overlay {
  position: fixed;
  top: 0;
  right: -100%; /* Start the overlay offscreen to the right */
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  opacity: 0;
  transition: right 0.3s $easeOut, opacity 0.3s $easeOut;
}

.modal-content {
  background-color: $white;
  padding: 20px;
  width: 100%; /* Change the width to 100% */
  height: 100%;
  overflow-y: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  h1 {
    margin-top: 1em;
  }
  h1 a {
    // color: initial;
    // text-decoration: none;
      transition: opacity 0.2s $easeOut;
    &:hover {
      opacity: .85;
    }
  }
  p {
    margin-top: 30px;
  }
}

.modal-overlay.open {
  right: 0; /* Slide the overlay in from the right */
  opacity: 1;
  margin-top: 0;
  .loader {
    opacity: 1;
    transition: opacity 0.3s $easeOut;
    &.loaded {
      opacity: 0;
      display: none;
    }
  }
  .image-container {
    display: flex;
    opacity: 0;
    transition: opacity 0.3s $easeOut;
    &.loaded {
      opacity: 1;
    }
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    img {
      width: 100%;
      height: auto;
      margin-bottom: 1em;
    }
    @include devices(tablet) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1em;
      align-items: flex-start;
      img {
        margin-bottom: 0;
      }
    }
    @include devices(desktop) {
    }
    @include devices(widescreen) {
      grid-template-columns: repeat(3, 1fr);
    }
    @include devices(fullhd) {
    }
  }
  .close-wrapper {
    position: relative;
  }
  .close {
    width: 50px;
    height: 50px;
    @include devices(desktop) {
      cursor: pointer;
    }
  }
  .close:hover {
    opacity: 1;
  }
  .close:before,
  .close:after {
    position: absolute;
    left: 25px;
    content: ' ';
    height: 50px;
    width: 2px;
    background-color: black;
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }
  & > * div {
    margin-top: 1em;
    &:first-child {
      margin-top: 0;
    }
  }
  .modal-tag-container {
    button {
      margin: 0 0.5em 0.5em 0;
      background-color: black;
      color: white;
    }
  }
}

.modal-tag-container {
  display: flex;
  flex-wrap: wrap;
  max-height: 5rem; /* Set the maximum height to two rows of tags */
  overflow: hidden;
  position: relative;
  transition: max-height 0.3s $easeOut;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1.5rem; /* Set the height of the gradient */
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      #fff
    ); /* Add a gradient to the bottom of the container */
    opacity: 1;
    transition: opacity 0.3s $easeOut;
    @include devices(widescreen) {
    opacity: 0;
  }
  }

  &.expanded, &.short {
    max-height: unset; /* Remove the maximum height on the expanded state */
    transition-timing-function: $easeOut;

    &::after {
      opacity: 0; /* Set the opacity to 0 */
      transition-timing-function: $easeOut;
    }
  }
}


