@import './easings.scss';

$nav-height: 4vh;

$black: #000000;
$white: #ffffff;
$gray: #D9D9D9;

$mobile-width: 600px;
$tablet-width: 900px;
$desktop-width: 1024px;

$easeIn: $easeInCirc;
$easeOut: $easeOutSine;

$mobile-min-width: 0px;
$mobile-max-width: 768px;
$tablet-min-width: $mobile-max-width + 1px;
$tablet-max-width: 1024px;
$desktop-min-width: $tablet-max-width + 1px;
$desktop-max-width: 1215px;
$widescreen-min-width: $desktop-max-width + 1px;
$widescreen-max-width: 1407px;
$fullhd-min-width: $widescreen-max-width + 1px;